<template>
  <div class="wrapper panel-with-actions">
    <thux-list-component
      :fields="getFields"
      :pagination="getPaginationFields"
      @select-per-page="changePerPage({ perPage: $event, id: uploadDocumentId })"
      @change-page="changePage({ page: $event, id: uploadDocumentId })"
      :action-select="getActionSelectFields"
      @select-action="setAction"
      @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
      @do-action="doAction($event, fieldName)"
      :advanced-search="getAdvancedSearchFields"
      @show-advanced-search="openAdvancedSearch"
      @open-edit-form="openEditForm()"
      @open-detail-form="openDetailForm()"
    >
      <template slot="body">
        <component-table
          :list="list.results"
          :goBack="goBack"
          :params="tableParams"
        />
      </template>
    </thux-list-component>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'
import { TYPES as AGREEMENT_DOCUMENT_HISTORY_TYPES } from './store'
import DocumentHistoryTable from './DocumentHistoryTable'

export default {
  name: 'AgreementDocumentHistoryList',
  props: { agreementId: { required: true } },
  mixins: [ThuxListMixin],
  components: {
    'component-table': DocumentHistoryTable
  },
  data () {
    return {
      searchFields: [],
      showCommonFilters: false
    }
  },
  computed: {
    ...mapGetters({
      ...AGREEMENT_DOCUMENT_HISTORY_TYPES.GENERIC.agreement.agreementdocumenthistory.LIST.GETTERS
    }),
    showList () {
      return true
    },
    canSeeActionSelect () {
      return false
    },
    canAddInstance () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      ...AGREEMENT_DOCUMENT_HISTORY_TYPES.GENERIC.agreement.agreementdocumenthistory.LIST.MUTATIONS
    }),
    ...mapActions({
      ...AGREEMENT_DOCUMENT_HISTORY_TYPES.GENERIC.agreement.agreementdocumenthistory.LIST.ACTIONS
    }),
    getComponentList () {
      this.setOrderBy('-validity_start')
      this.setFilters({ filter__agreement__id: this.agreementId })
    }
  }
}
</script>
