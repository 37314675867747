<template>
  <div :class="!isDetail ? 'wrapper sidebar-collapsed' : ''" v-has-perms="rolePerm">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed">
        <thux-list-header
          v-if="showHeader"
          :title="title"
          :go-to="goToRouter"
          :icon-active-filters="getIconFiltersFields"
          :intermediate-breadcrumbs="intermediateBreadcrumbs"
        />
        <component-edit
          v-if="showEditForm && editComponent"
          :id="componentId"
          :editParams="editParams"
          @close-form='closeEditForm'
        />
        <component-detail
          v-if="showDetailForm && detailComponent"
          :id="componentId"
          :detailParams="detailParams"
          @close-form='closeDetailForm'
        />
        <component-new-revision
          v-if="showRevisionForm && detailComponent"
          :id="componentId"
          :detailParams="detailParams"
          @close-form='closeRevisionForm'
        />

        <advanced-search
          v-show="showAdvancedSearch"
          v-if="toggleAdvancedSearch"
          :fields="searchFields"
          :reset="reset"
          @search="addFiltersToInstance"
          @reset="removeFiltersToInstance"
          @active="activeFilters = $event"
        />
        <panel :title="panelTitle" :no-button="true">
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
            @open-new-revision-form="openRevisionForm()"
          >
            <template slot="body">
              <component-table
                :list="list.results"
                :goBack="goBack"
                :params="tableParams"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
                @open-new-revision-form="openRevisionForm"
                @show-history-modal="showHistoryModal"
              />
            </template>
          </thux-list-component>
        </panel>
      </div>
    </portal-target>
    <router-view />
    <portal-target
      v-if="showCommonFilters"
      v-show="showList"
      name="layout-sidebar"
      class="layout-side panel-scroll-full"
    >
      <button
        @click.prevent="closeRightSidebar"
        class="btn btn-primary text-uppercase w-100 mb-4"
        :aria-label="$t('Close')"
      >
        <i class="fas fa-times" />
        {{ $t('Hide sidebar') }}
      </button>
      <common-filters />
    </portal-target>
    <document-history-modal
      v-if="showDocumentHistoryModal"
      :agreement-id="modalItem.agreement"
      :client-name="modalItem.organization_name"
      :agreement-title="modalItem.title"
      :title="$t('Agreement documents')"
      @close="closeHistoryModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as AGREEMENT_DOCUMENT_TYPES } from './store'
import { ROUTES as AGREEMENT_DOCUMENT_ROUTES } from './router'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../organization/organization/organization/customer-organization/router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import AgreementDocumentTable from './AgreementDocumentTable.vue'
import AgreementDocumentFilters from './AgreementDocumentCommonFilters'
import AgreementDocumentEdit from './AgreementDocumentEdit.vue'
import AgreementDocumentNewRevision from './AgreementDocumentNewRevision.vue'
import { ORGANIZATION_CUSTOMER_CODE } from '@/const'
import DocumentHistoryModal from './history/DocumentHistoryModal.vue'

export default {
  name: 'AgreementDocumentList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': AgreementDocumentTable,
    'common-filters': AgreementDocumentFilters,
    'component-edit': AgreementDocumentEdit,
    'component-detail': AgreementDocumentEdit,
    'component-new-revision': AgreementDocumentNewRevision,
    'document-history-modal': DocumentHistoryModal
  },
  mounted () {
    if (this.$route.params.id) {
      this.openDetailForm(this.$route.params.id)
    }
  },
  data () {
    const searchfields = [
      {
        key: 'filter__title__icontains',
        placeholder: this.$t('Agreement object'),
        type: 'text',
        col: 6
      },
      {
        key: 'filter__validity_start__gte',
        placeholder: this.$t('Validity start date'),
        type: 'date',
        col: 6
      },
      {
        key: 'filter__validity_end__gte',
        placeholder: this.$t('Validity end date'),
        type: 'date',
        col: 6
      }
    ]
    if (!this.$route.params.orgId) {
      searchfields.unshift(
        {
          key: 'filter__agreement__customer__name__icontains',
          placeholder: this.$t('Customer'),
          type: 'text',
          col: 6
        }
      )
    }
    return {
      AGREEMENT_DOCUMENT_ROUTES,
      CUSTOMER_ORG_ROUTES,
      ORGANIZATION_CUSTOMER_CODE,
      title: this.$t('Agreements'),
      rolePerm: 'agreement_agreementdocument_list',
      actionEnablePermission: ['agreement_agreementdocument_enable'],
      actionDisablePermission: ['agreement_agreementdocument_disable'],
      fieldName: 'id',
      organizationName: null,
      searchFields: searchfields,
      showRevisionForm: false,
      modalItem: undefined,
      showDocumentHistoryModal: false
    }
  },
  computed: {
    ...mapGetters({
      ...AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.LIST.GETTERS
    }),
    canAddInstance () {
      return hasPerm('agreement_agreementdocument_create')
    },
    canSeeActionSelect () {
      return true
    }
  },
  methods: {
    ...mapMutations({
      setOrderBy: AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.LIST.MUTATIONS.setOrderBy,
      setSelectedList: AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.LIST.MUTATIONS.setSelectedList,
      setSelectAll: AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.LIST.ACTIONS
    }),
    getComponentList () {
      this.setOrderBy('agreement__customer__name.-validity_start')
      if (this.$route.params.orgId) {
        this.setFilters({ filter__agreement__customer: this.$route.params.orgId })
        this.getOrganizationchoices({ filter__id: this.$route.params.orgId }).then(() => {
          this.$set(this, 'title', `${this.$t('Agreements')} ${this.organizationChoices.results[0].name}`)
          this.$set(this, 'intermediateBreadcrumbs', [
            {
              title: this.$t('Organizations'),
              routerName: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_LIST,
              routerParams: { orgType: ORGANIZATION_CUSTOMER_CODE }
            },
            {
              title: this.$t('Customer organization'),
              routerName: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL,
              routerParams: { orgType: ORGANIZATION_CUSTOMER_CODE, id: this.$route.params.orgId }
            }
          ])
        })
      } else {
        this.setFilters({ })
      }
    },
    openRevisionForm (id) {
      this.$set(this, 'componentId', id)
      this.$set(this, 'showRevisionForm', true)
      this.$set(this, 'showEditForm', false)
      this.$set(this, 'showDetailForm', false)
    },
    closeRevisionForm () {
      this.$set(this, 'showRevisionForm', false)
      this.$set(this, 'showEditForm', false)
      this.$set(this, 'showDetailForm', false)
    },
    showHistoryModal (item) {
      this.$set(this, 'modalItem', item)
      this.$set(this, 'showDocumentHistoryModal', true)
      this.$set(this, 'showRevisionForm', false)
      this.$set(this, 'showEditForm', false)
      this.$set(this, 'showDetailForm', false)
    },
    closeHistoryModal () {
      this.$set(this, 'modalItem', undefined)
      this.$set(this, 'showDocumentHistoryModal', false)
    }
  }
}
</script>
