<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '../../../../main'
import { TYPES as AGREEMENT_DOCUMENT_TYPES } from '../store'

export default {
  name: 'OrganizationMixin',
  computed: {
    ...mapGetters({
      organizationChoices: AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.LIST.GETTERS.organizationChoices
    }),
    organizationListOptions () {
      if (this.organizationChoices && this.organizationChoices.results) {
        return this.organizationChoices.results.map(item => {
          return {
            value: item.id,
            text: item.name + (item.get_typology_display ? ` (${item.get_typology_display})` : '')
          }
        })
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setOrganizationchoices: AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.LIST.MUTATIONS.setOrganizationchoices
    }),
    ...mapActions({
      getOrganizationchoices: AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.LIST.ACTIONS.getOrganizationchoices
    }),
    onSearchOrganization (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', true)
        this.getOrganizationchoices({ filter__name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', false)
        })
      }
    },
    selectOrganization (item) {
      this.$set(this.form, 'customer', item.value)
      this.$set(this.form, 'customer_name', item.text)
    },
    clearOrganization () {
      this.$set(this.form, 'customer', null)
      this.$set(this.form, 'customer_name', null)
    }
  }
}
</script>
