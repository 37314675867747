<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            v-if="!id && !orgId"
            :validator="$v.form.customer"
            :label-form="'Customer' | translate"
            label-search="text"
            class-form="col-lg-12 mb-2"
            :value="form.customer ? { value: form.customer, text: form.customer_name } : null"
            :placeholder="$t('Select customer')"
            :options="this.organizationListOptions"
            @search="onSearchOrganization"
            @select="selectOrganization"
            @clear="clearOrganization"
          />
          <form-thux-horizontal-input
            v-else
            :label-form="'Customer' | translate"
            class-form="col-lg-12 mb-2"
            type="text"
            :value="form.organization_name"
            :disabled="true"
          />
          <form-thux-horizontal-text-area
            :validator="$v.form.title"
            :external-errors="errors['title']"
            :label-form="'Agreement object' | translate"
            class-form="col-lg-12 mb-2"
            type="text"
            :value="form.title"
            :disabled="!formEditable"
            @change="$set(form, 'title', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-file
            :validator="$v.form.attachment"
            :label-form="'Document' | translate"
            class-form="col-12 my-2"
            :value="form.attachment"
            :disabled="!formEditable"
            :placeholder="$t('Choose a file or drop it here...')"
            :drop-placeholder="$t('Drop file here...')"
            @input="$set(form, 'attachment', $event)"
          />
          <div v-if="id" class="col-12">
            <small class="form-text text-muted">
              {{ $t('Previously selected document')}}: {{ detail.simple_filename }}
            </small>
          </div>
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-text-area
            :validator="$v.form.description"
            :external-errors="errors['description']"
            :label-form="'Description' | translate"
            class-form="col-12 my-2"
            :value="form.description"
            :disabled="!formEditable"
            @change="$set(form, 'description', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-datetime
            :validator="$v.form.validity_start"
            :external-errors="errors['validity_start']"
            :label-form="'Validity start date' | translate"
            class-form="col-lg-6 my-2"
            labelColsLg="4"
            type="date"
            :value="validity_start"
            :disabled="!formEditable"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'validity_start')"
          />
          <form-thux-horizontal-datetime
            :validator="$v.form.validity_end"
            :external-errors="errors['validity_end']"
            :label-form="'Validity end date' | translate"
            class-form="col-lg-6 my-2"
            labelColsLg="4"
            type="date"
            :value="validity_end"
            :disabled="!formEditable"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'validity_end')"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <div class="mt-3 f-w-600 d-flex justify-content-end">
          <b-button
            class="mr-3"
            variant="default"
            @click.prevent="formEditable ? cancel() : closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            variant="primary"
            v-has-perms="editRolePerm"
            @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
            :disabled="$v.form.$invalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as AGREEMENT_DOCUMENT_TYPES } from './store'
import { TYPES as AGREEMENT_TYPES } from '../agreement/store'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import OrganizationMixin from './mixins/OrganizationMixin.vue'
import { storeMixin } from '@/storeMixin'
import moment from 'moment'

export default {
  name: 'AgreementDocumentEdit',
  mixins: [ThuxDetailPanelMixin, OrganizationMixin],
  data () {
    return {
      pageName: 'Document',
      rolePerm: 'agreement_agreementdocument_retrieve',
      editRolePerm: 'agreement_agreementdocument_update',
      form: {
        title: '',
        description: '',
        validity_start: null,
        validity_end: null,
        document: null,
        attachment: null,
        customer: null
      },
      validity_start: null,
      validity_end: null,
      orgId: this.$route.params.orgId
    }
  },
  computed: {
    ...mapGetters({
      ...AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.DETAIL.GETTERS,
      agreement: AGREEMENT_TYPES.GENERIC.agreement.agreement.DETAIL.GETTERS.detail
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.DETAIL.ACTIONS,
      createAgreement: AGREEMENT_TYPES.GENERIC.agreement.agreement.DETAIL.ACTIONS.create,
      updateAgreement: AGREEMENT_TYPES.GENERIC.agreement.agreement.DETAIL.ACTIONS.update
    }),
    setForm () {
      const form = Object.assign({}, this.detail)
      this.$set(this, 'form', form)
      this.$set(this.form, 'customer', form.agreement_data.customer)
      this.$set(this, 'validity_start', moment(new Date(form.validity_start)))
      this.$set(this, 'validity_end', moment(new Date(form.validity_end)))
    },
    initFields () {
      if (!this.id && this.orgId) {
        this.getOrganizationchoices({ filter__id: this.orgId }).then(() => {
          this.$set(this.form, 'customer', this.organizationListOptions[0].value)
          this.$set(this.form, 'organization_name', this.organizationListOptions[0].text)
        })
      }
    },
    async onSubmit () {
      const {
        update: updateAgreementDocument,
        create: createAgreementDocument
      } = this
      const { updateAgreement, createAgreement } = this
      const actionAgreement = this.id ? updateAgreement : createAgreement
      const actionAgreementDocument = this.id ? updateAgreementDocument : createAgreementDocument
      let formData = this.getFormData()
      formData.document = formData.attachment
      delete formData.attachment
      if (!formData.validity_end) {
        delete formData.validity_end
      }
      formData = storeMixin.utils.parseFormMultipart(formData, ['document'])
      const { client, ...rest } = formData
      if (!this.id) {
        await actionAgreement({ customer: formData.customer })
      }
      await actionAgreementDocument({ agreement: this.agreement.id, ...rest })
      setTimeout(() => {
        if (!this.id) {
          this.closeForm()
        } else {
          this.makeFormReadonly()
        }
      }, 500)
    }
  },
  validations () {
    const form = {
      customer: { required },
      title: { required },
      attachment: { required },
      description: { },
      validity_start: { required },
      validity_end: { }
    }
    if (this.id) {
      form.attachment = { }
    }
    return { form }
  }
}
</script>
