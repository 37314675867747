<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.customer"
            :label-form="'Customer' | translate"
            class-form="col-lg-12 mb-2"
            type="text"
            :value="form.organization_name"
            :disabled="true"
          />
          <form-thux-horizontal-text-area
            :validator="$v.form.title"
            :external-errors="errors['title']"
            :label-form="'Agreement object' | translate"
            class-form="col-lg-12 mb-2"
            type="text"
            :value="form.title"
            :disabled="true"
            @change="$set(form, 'title', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-file
            :validator="$v.form.attachment"
            :label-form="'Document' | translate"
            class-form="col-12 my-2"
            :value="form.attachment"
            :placeholder="$t('Choose a file or drop it here...')"
            :drop-placeholder="$t('Drop file here...')"
            @input="$set(form, 'attachment', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-text-area
            :validator="$v.form.description"
            :external-errors="errors['description']"
            :label-form="'Description' | translate"
            class-form="col-12 my-2"
            :value="form.description"
            @change="$set(form, 'description', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-datetime
            :validator="$v.form.validity_start"
            :external-errors="errors['validity_start']"
            :label-form="'Validity start date' | translate"
            class-form="col-lg-6 my-2"
            labelColsLg="4"
            type="date"
            :value="validity_start"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'validity_start')"
          />
          <form-thux-horizontal-datetime
            :validator="$v.form.validity_end"
            :external-errors="errors['validity_end']"
            :label-form="'Validity end date' | translate"
            class-form="col-lg-6 my-2"
            labelColsLg="4"
            type="date"
            :value="validity_end"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'validity_end')"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <div class="mt-3 f-w-600 d-flex justify-content-end">
          <b-button
            class="mr-3"
            variant="default"
            @click.prevent="formEditable ? cancel() : closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            variant="primary"
            v-has-perms="editRolePerm"
            @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
            :disabled="$v.form.$invalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as AGREEMENT_DOCUMENT_TYPES } from './store'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import OrganizationMixin from './mixins/OrganizationMixin.vue'
import { storeMixin } from '@/storeMixin'
// import moment from 'moment'

export default {
  name: 'AgreementDocumentNewRevision',
  mixins: [ThuxDetailPanelMixin, OrganizationMixin],
  data () {
    return {
      pageName: 'Document',
      title: this.$t('Add new version'),
      rolePerm: 'agreement_agreementdocument_retrieve',
      editRolePerm: 'agreement_agreementdocument_new_revision',
      form: {
        title: '',
        description: '',
        validity_start: null,
        validity_end: null,
        document: null,
        attachment: null,
        customer: null
      },
      validity_start: null,
      validity_end: null,
      orgId: this.$route.params.orgId
    }
  },
  computed: {
    ...mapGetters({
      ...AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.DETAIL.ACTIONS,
      getList: AGREEMENT_DOCUMENT_TYPES.GENERIC.agreement.agreementdocument.LIST.ACTIONS.getList
    }),
    setForm () {
      this.$set(this.form, 'title', this.detail.title)
      this.$set(this.form, 'customer', this.detail.agreement_data.customer)
      this.$set(this.form, 'organization_name', this.detail.organization_name)
      this.$set(this.form, 'agreement', this.detail.agreement)
      this.makeFormEditable()
    },
    async onSubmit () {
      let formData = this.getFormData()
      formData.originalDoc = this.detail.id
      formData.document = formData.attachment
      delete formData.attachment
      if (!formData.validity_end) {
        delete formData.validity_end
      }
      formData = storeMixin.utils.parseFormMultipart(formData, ['document'])
      await this.newRevisionAgreementDocument(formData)
      setTimeout(() => {
        this.getList()
        this.closeForm()
      }, 500)
    }
  },
  validations () {
    const form = {
      customer: { required },
      title: { required },
      attachment: { required },
      description: { },
      validity_start: { required },
      validity_end: { }
    }
    return { form }
  }
}
</script>
