<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll"
    @check-none="checkNone"
    @set-selected-row="setSelectedRow"
    :show-checks="showChecks"
    :showDetailButton="showDetailButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-detail-form="$emit('open-detail-form', $event)"
  >
    <template slot="other-buttons-actions-bottom" slot-scope="row">
      <b-btn v-if="row.item.document"
        variant="outline-primary"
        v-has-perms="downloadDocumentRolePerm"
        :title="'download' | translate"
        @click="downloadDocument(row.item)"
      >
        <i class="fas fa-download"></i>
      </b-btn>
    </template>
  </thux-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as AGREEMENT_DOCUMENT_HISTORY_TYPES } from './store'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'DocumentHistoryTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        {
          key: 'description',
          label: this.$t('Description'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: false,
          value: 'description'
        },
        {
          key: 'validity_start',
          label: this.$t('Validity start'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          value: 'validity_start'
        },
        {
          key: 'validity_end',
          label: this.$t('Validity end'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          value: 'validity_end'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: [],
      // Permissions
      downloadDocumentRolePerm: ['agreement_agreementdocument_download_document']
    }
  },
  computed: {
    ...mapGetters({
      selectAll: AGREEMENT_DOCUMENT_HISTORY_TYPES.GENERIC.agreement.agreementdocumenthistory.LIST.GETTERS.selectAll,
      selectedList: AGREEMENT_DOCUMENT_HISTORY_TYPES.GENERIC.agreement.agreementdocumenthistory.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setList: AGREEMENT_DOCUMENT_HISTORY_TYPES.GENERIC.agreement.agreementdocumenthistory.LIST.MUTATIONS.setList,
      setSelectedList:
        AGREEMENT_DOCUMENT_HISTORY_TYPES.GENERIC.agreement.agreementdocumenthistory.LIST.MUTATIONS.setSelectedList,
      setSelectAll: AGREEMENT_DOCUMENT_HISTORY_TYPES.GENERIC.agreement.agreementdocumenthistory.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...AGREEMENT_DOCUMENT_HISTORY_TYPES.GENERIC.agreement.agreementdocumenthistory.LIST.ACTIONS
    }),
    downloadDocument (item) {
      const fileName = `Contratto_${item.organization_name}.${item.simple_filename.split('.').pop()}`
      this.download({
        id: item.id,
        action: 'download-document',
        simpleFileName: fileName
      })
    }
  }
}
</script>
